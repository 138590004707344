.style {
	z-index: -1;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(#eef2fb, #e8e6f6);
	transition: all 0.6s ease;
}

.svg {
	width: 100%;
	height: 100%;
}

.canvas {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.circle {
	fill: none;
	/* stroke: white; */
	stroke: #d3b8e8;
	opacity: 0.2;
	/* stroke-width: 20px; */
}
